export const setUser = (
  accessToken,
  refreshToken,
  accessTokenExpires,
  refreshTokenExpires,
  userId,
  userName,
  userDisplayName,
  userRoles,
  isVerified,
  rememberMe,
  captchaRequestToken,
  doShowSignIn
) => {
  return {
    type: "SET_USER",
    payload: {
      accessToken: accessToken,
      refreshToken: refreshToken,
      accessTokenExpires: accessTokenExpires,
      refreshTokenExpires: refreshTokenExpires,
      userId: userId,
      userName: userName,
      userDisplayName: userDisplayName,
      userRoles: userRoles,
      isVerified: isVerified,
      rememberMe: rememberMe,
      captchaRequestToken: captchaRequestToken,
      doShowSignIn: doShowSignIn,
    },
  };
};

export const updateUser = (userName, userDisplayName, isVerified) => {
  return {
    type: "UPDATE_USER",
    payload: {
      userName: userName,
      userDisplayName: userDisplayName,
      isVerified: isVerified,
    },
  };
};

export const updateUserPersonalInfo = (userName, isVerified) => {
  return {
    type: "UPDATE_USER_PERSONAL_INFO",
    payload: {
      userName: userName,
      isVerified: isVerified,
    },
  };
};

export const updateUserPublicProfile = (userDisplayName) => {
  return {
    type: "UPDATE_USER_PUBLIC_PROFILE",
    payload: {
      userDisplayName: userDisplayName,
    },
  };
};

export const setToken = (accessToken, accessTokenExpires) => {
  return {
    type: "SET_TOKEN",
    payload: {
      accessToken: accessToken,
      accessTokenExpires: accessTokenExpires,
    },
  };
};

export const rehydrate = (state) => {
  return {
    type: "REHYDRATE",
    payload: state,
  };
};

export const reset = () => {
  return {
    type: "RESET",
  };
};

export const setCaptchaRequestToken = (captchaRequestToken) => {
  return {
    type: "SET_CAPTCHA_REQUEST_TOKEN",
    payload: {
      captchaRequestToken: captchaRequestToken,
    },
  };
};

export const setDoShowSignIn = (doShowSignIn) => {
  return {
    type: "SET_DO_SHOW_SIGN_IN",
    payload: {
      doShowSignIn: doShowSignIn,
    },
  };
};
